<template>
  <div>
    <div class="a-w-100 a-mt-20 a-fw-b a-c-333">
      <div class="a-flex-rfsc">
        <div class="a-fw-b">参数来源:</div>
        <div class="a-ml-15 a-fw-b">
          {{
            form.paramSouce == 1
              ? "系统默认参数"
              : form.paramSource == 2
              ? "上次保存参数"
              : "实时获取"
          }}
        </div>
      </div>
      <div class="a-flex-rfsc a-mt-15">
        <div>实时获取时间:</div>
        <div>{{ form.realParamTime }}</div>
      </div>
    </div>
    <el-form
      ref="form"
      class="a-mt-24"
      :model="form"
      :rules="rules"
      :inline="true"
      label-width="112px"
      label-position="left"
    >
      <el-form-item label="端口最大功率" prop="slotMaxPower">
        <el-input
          v-model="form.slotMaxPower"
          style="width: 370px; margin-right: 32px"
          placeholder="请输入"
          @input="form.slotMaxPower = form.slotMaxPower.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">W</span>
        </el-input>
      </el-form-item>
      <el-form-item label="整机最大功率" prop="totalMaxPower">
        <el-input
          v-model="form.totalMaxPower"
          style="width: 370px"
          placeholder="请输入"
          @input="form.totalMaxPower = form.totalMaxPower.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">W</span>
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="投币一元充电数值" prop="coinChargingNum" >
                <el-input 
                    style="width:370px;margin-right: 32px;" 
                    v-model="form.coinChargingNum"
                    @input="form.coinChargingNum=form.coinChargingNum.replace(/^\.+|[^\d]+/g,'')"
                    placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">分钟/瓦时</span>
                </el-input>
            </el-form-item>
            <el-form-item label="刷卡一元充电数值" prop="cardChargingNum" >
                <el-input 
                    style="width:370px;" 
                    v-model="form.cardChargingNum"
                    @input="form.cardChargingNum=form.cardChargingNum.replace(/^\.+|[^\d]+/g,'')"
                    placeholder="请输入">
                    <span slot="suffix" class="cy_suffix">分钟/瓦时</span>
                </el-input>
            </el-form-item> -->
      <el-form-item label="免费充电时长" prop="freeChargingMinute">
        <el-input
          v-model="form.freeChargingMinute"
          style="width: 370px; margin-right: 32px"
          placeholder="请输入"
          @input="form.freeChargingMinute = form.freeChargingMinute.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">分钟</span>
        </el-input>
      </el-form-item>
      <el-form-item label="移除断电时长" prop="removeTime">
        <el-input
          v-model="form.removeTime"
          style="width: 370px"
          placeholder="请输入"
          @input="form.removeTime = form.removeTime.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">秒</span>
        </el-input>
      </el-form-item>
      <el-form-item label="未接负载等待时长" prop="notPluginWaitTime">
        <el-input
          v-model="form.notPluginWaitTime"
          style="width: 370px; margin-right: 32px"
          placeholder="请输入"
          @input="form.notPluginWaitTime = form.notPluginWaitTime.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">秒</span>
        </el-input>
      </el-form-item>
      <el-form-item label="免费充电模式" prop="freeChargingMode">
        <el-switch
          v-model="form.freeChargingMode"
          style="width: 370px"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
      </el-form-item>
      <el-form-item label="退费模式" prop="refundMode">
        <el-switch
          v-model="form.refundMode"
          style="width: 370px; margin-right: 32px"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
      </el-form-item>
      <el-form-item label="充满自停" prop="fullStop">
        <el-switch
          v-model="form.fullStop"
          style="width: 370px"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
      </el-form-item>
      <el-form-item label="投币功能" prop="coinMode">
        <el-switch
          v-model="form.coinMode"
          style="width: 370px; margin-right: 32px"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
      </el-form-item>
      <el-form-item label="刷卡功能" prop="cardMode">
        <el-switch
          v-model="form.cardMode"
          style="width: 370px"
          :active-value="1"
          :inactive-value="0"
        ></el-switch>
      </el-form-item>
      <el-form-item label="浮充功率" prop="floatPower">
        <el-input
          v-model="form.floatPower"
          style="width: 370px; margin-right: 32px"
          placeholder="请输入"
          type="number"
        >
          <span slot="suffix" class="cy_suffix">W</span>
        </el-input>
      </el-form-item>
      <el-form-item label="浮充时长" prop="floatTime">
        <el-input
          v-model="form.floatTime"
          style="width: 370px"
          placeholder="请输入"
          @input="form.floatTime = form.floatTime.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">秒</span>
        </el-input>
      </el-form-item>
      <el-form-item label="单次刷卡金额" prop="cardPerChargingAmount">
        <el-input
          v-model="form.cardPerChargingAmount"
          style="width: 370px; margin-right: 32px"
          placeholder="请输入"
          @input="
            form.cardPerChargingAmount = form.cardPerChargingAmount.replace(/^\.+|[^\d]+/g, '')
          "
        >
          <span slot="suffix" class="cy_suffix">角</span>
        </el-input>
      </el-form-item>
      <el-form-item label="设备音量" prop="volume">
        <el-input
          v-model="form.volume"
          style="width: 370px"
          placeholder="请输入"
          @input="form.volume = form.volume.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">(0-7)</span>
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="计量类型" prop="chargingUnitMode" >
                <el-select style="width:370px;margin-right: 32px;" v-model="form.chargingUnitMode" placeholder="请选择">
                  <el-option label="按时" :value="1"></el-option>
                  <el-option label="按量" :value="2"></el-option>
                </el-select>
            </el-form-item> -->
      <el-form-item label="温度报警阈值" prop="limitTemp">
        <el-input
          v-model="form.limitTemp"
          style="width: 370px; margin-right: 32px"
          placeholder="请输入"
          @input="form.limitTemp = form.limitTemp.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">℃</span>
        </el-input>
      </el-form-item>
      <el-form-item label="烟雾报警阈值" prop="limitSmoke">
        <el-input
          v-model="form.limitSmoke"
          style="width: 370px"
          placeholder="请输入"
          @input="form.limitSmoke = form.limitSmoke.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">ppm</span>
        </el-input>
      </el-form-item>
      <el-form-item label="机器密码" prop="devicePassword">
        <el-input
          v-model="form.devicePassword"
          style="width: 370px; margin-right: 32px"
          maxlength="4"
          placeholder="请输入"
          @input="form.devicePassword = form.devicePassword.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">kwh</span>
        </el-input>
      </el-form-item>
      <el-form-item label="充电状态上报间隔" prop="statusUpTime">
        <el-input
          v-model="form.statusUpTime"
          style="width: 370px"
          placeholder="请输入"
          @input="form.statusUpTime = form.statusUpTime.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">分钟</span>
        </el-input>
      </el-form-item>
      <el-form-item label="烟雾与温度上报间隔" prop="tempUpTime">
        <el-input
          v-model="form.tempUpTime"
          style="width: 370px; margin-right: 32px"
          placeholder="请输入"
          @input="form.tempUpTime = form.tempUpTime.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">分钟</span>
        </el-input>
      </el-form-item>
      <el-form-item label="端口状态上报间隔" prop="slotStatusUpTime">
        <el-input
          v-model="form.slotStatusUpTime"
          style="width: 370px"
          placeholder="请输入"
          @input="form.slotStatusUpTime = form.slotStatusUpTime.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">分钟</span>
        </el-input>
      </el-form-item>
      <el-form-item label="操作间隔" prop="optionTime">
        <el-input
          v-model="form.optionTime"
          style="width: 370px"
          placeholder="请输入"
          @input="form.optionTime = form.optionTime.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">分钟</span>
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ["setType", "deviceId"],
  data() {
    const checkVolume = (rule, value, callback) => {
      if (value > 7 || value < 0) {
        callback(new Error("设备音量范围值为0-7"));
      } else {
        callback();
      }
    };
    return {
      form: {
        paramSource: 0,
        slotMaxPower: "",
        totalMaxPower: "",
        coinChargingNum: "",
        cardChargingNum: "",
        freeChargingMinute: "",
        removeTime: "",
        notPluginWaitTime: "",
        freeChargingMode: "",
        limitTemp: "",
        limitSmoke: "",
        devicePassword: "",
        statusUpTime: "",
        tempUpTime: "",
        refundMode: "",
        fullStop: "",
        coinMode: "",
        cardMode: "",
        floatPower: "",
        floatTime: "",
        cardPerChargingAmount: "",
        volume: "",
        chargingUnitMode: "",
        optionTime: "",
        slotStatusUpTime: "",
      },
      rules: {
        slotMaxPower: [{ required: true, message: "请输入", trigger: "blur" }],
        totalMaxPower: [{ required: true, message: "请输入", trigger: "blur" }],
        coinChargingNum: [{ required: true, message: "请输入", trigger: "blur" }],
        cardChargingNum: [{ required: true, message: "请输入", trigger: "blur" }],
        freeChargingMinute: [{ required: true, message: "请输入", trigger: "blur" }],
        removeTime: [{ required: true, message: "请输入", trigger: "blur" }],
        notPluginWaitTime: [{ required: true, message: "请输入", trigger: "blur" }],
        // freeChargingMode: [{required: true, message:'请输入', trigger: 'blur'}],
        limitTemp: [{ required: true, message: "请输入", trigger: "blur" }],
        limitSmoke: [{ required: true, message: "请输入", trigger: "blur" }],
        devicePassword: [{ required: true, message: "请输入", trigger: "blur" }],
        statusUpTime: [{ required: true, message: "请输入", trigger: "blur" }],
        tempUpTime: [{ required: true, message: "请输入", trigger: "blur" }],
        // refundMode: [{required: true, message:'请输入', trigger: 'blur'}],
        // fullStop: [{required: true, message:'请输入', trigger: 'blur'}],
        // coinMode: [{required: true, message:'请输入', trigger: 'blur'}],
        // cardMode: [{required: true, message:'请输入', trigger: 'blur'}],
        floatPower: [{ required: true, message: "请输入", trigger: "blur" }],
        floatTime: [{ required: true, message: "请输入", trigger: "blur" }],
        cardPerChargingAmount: [{ required: true, message: "请输入", trigger: "blur" }],
        volume: [
          { required: true, message: "请输入", trigger: "blur" },
          { validator: checkVolume, trigger: "blur" },
        ], // 数值限制
        //chargingUnitMode: [{required: true, message:'请选择', trigger: 'change'}],
        optionTime: [{ required: true, message: "请输入", trigger: "blur" }],
        slotStatusUpTime: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  watch: {
    form(val) {
      this.$emit("input", val);
    },
    setType: {
      immediate: true,
      handler(val) {
        if (val == "set") {
          this.getDefaultParams();
        } else if (val == "reset") {
          this.form = JSON.parse(window.localStorage.getItem("devparams10"));
        } else if (val == "single") {
          this.getParams();
        }
      },
    },
  },
  mounted() {},
  methods: {
    getDefaultParams() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getYd10DeviceDefaultParam,
          method: "get",
          params: {},
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.form = res.result.data;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    getParams() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getYd10DeviceParam,
          method: "get",
          params: {
            deviceId: this.deviceId,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.form = res.result.data;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    check() {
      return new Promise((resolve, reject) => {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            resolve(true);
          } else {
            reject("十路");
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
  align-items: flex-start !important;
  line-height: 22px;
}
</style>
