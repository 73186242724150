<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="1056px"
    >
      <div slot="title" class="title">下发结果</div>
      <div class="a-fs-16 a-c-master a-fw-500 mgb28">下发成功: {{ successNum }}台</div>
      <div class="a-flex-rsbc">
        <span class="a-fs-16 a-c-master a-fw-500">下发失败: {{ failNum }}台</span>
        <el-button v-if="failNum" type="primary" @click="resetDevParams">重试</el-button>
      </div>
      <el-table :data="tableData" class="a-mt-16">
        <el-table-column label="设备号" min-width="128" prop="">
          <template slot-scope="{ row }">
            <span>{{ row.deviceCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下发时间" min-width="200">
          <template slot-scope="{ row }">
            <span>{{ row.response.time }}</span>
          </template>
        </el-table-column>
        <el-table-column label="失败原因" min-width="376">
          <template slot-scope="{ row }">
            <span>{{ row.response.msg }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作时间" min-width="304">
          <template slot-scope="{ row }">
            <span>{{ row.response.time }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button type="primary" @click="submit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      tableData: [],
    };
  },
  computed: {
    successNum() {
      const arr = this.tableData.filter((res) => {
        return res.response.code == 0;
      });
      return arr.length || 0;
    },
    failNum() {
      const arr = this.tableData.filter((res) => {
        return res.response.code != 0;
      });
      return arr.length || 0;
    },
  },
  methods: {
    submit() {
      this.dialogVisible = false;
    },
    resetDevParams() {
      const arr = this.tableData.filter((res) => {
        return res.response.code != 0;
      });
      this.dialogVisible = false;
      this.$emit("resetParams", arr);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebf0f5;
}
.mgb28 {
  margin-bottom: 28px;
}
</style>
