<template>
  <div>
    <el-form
      ref="form"
      class="a-mt-24"
      :model="form"
      :rules="rules"
      :inline="true"
      label-width="112px"
      label-position="left"
    >
      <el-form-item label="过流限值" prop="limitAmpere">
        <el-input
          v-model="form.limitAmpere"
          style="width: 370px; margin-right: 32px"
          type="number"
          placeholder="请输入"
        >
          <span slot="suffix" class="cy_suffix">A</span>
        </el-input>
      </el-form-item>
      <el-form-item label="功率限值" prop="limitPower">
        <el-input
          v-model="form.limitPower"
          style="width: 370px"
          placeholder="请输入"
          @input="form.limitPower = form.limitPower.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">W</span>
        </el-input>
      </el-form-item>
      <el-form-item label="状态上报周期" prop="statusCycleTime">
        <el-input
          v-model="form.statusCycleTime"
          style="width: 370px; margin-right: 32px"
          placeholder="请输入"
          @input="form.statusCycleTime = form.statusCycleTime.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">分钟</span>
        </el-input>
      </el-form-item>
      <el-form-item label="心跳周期" prop="heartCycleTime">
        <el-input
          v-model="form.heartCycleTime"
          style="width: 370px"
          placeholder="请输入"
          @input="form.heartCycleTime = form.heartCycleTime.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">秒</span>
        </el-input>
      </el-form-item>
      <el-form-item label="充满续充时长" prop="fullChargeDelay">
        <el-input
          v-model="form.fullChargeDelay"
          style="width: 370px; margin-right: 32px"
          placeholder="请输入"
          @input="form.fullChargeDelay = form.fullChargeDelay.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">秒</span>
        </el-input>
      </el-form-item>
      <el-form-item label="空载延时时长" prop="nullChargeDelay">
        <el-input
          v-model="form.nullChargeDelay"
          style="width: 370px"
          placeholder="请输入"
          @input="form.nullChargeDelay = form.nullChargeDelay.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">秒</span>
        </el-input>
      </el-form-item>
      <el-form-item label="充满功率阈值" prop="fullChargePower">
        <el-input
          v-model="form.fullChargePower"
          style="width: 370px; margin-right: 32px"
          placeholder="请输入"
          @input="form.fullChargePower = form.fullChargePower.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">W</span>
        </el-input>
      </el-form-item>
      <el-form-item label="空载功率阈值" prop="nullChargePower">
        <el-input
          v-model="form.nullChargePower"
          style="width: 370px"
          placeholder="请输入"
          @input="form.nullChargePower = form.nullChargePower.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">W</span>
        </el-input>
      </el-form-item>
      <el-form-item label="高温阈值" prop="limitTemp">
        <el-input
          v-model="form.limitTemp"
          style="width: 370px; margin-right: 32px"
          placeholder="请输入"
          @input="form.limitTemp = form.limitTemp.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">℃</span>
        </el-input>
      </el-form-item>
      <el-form-item label="最大充电时长" prop="limitTime">
        <el-input
          v-model="form.limitTime"
          style="width: 370px"
          placeholder="请输入"
          @input="form.limitTime = form.limitTime.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">分钟</span>
        </el-input>
      </el-form-item>
      <el-form-item label="涓流阈值" prop="limitTrickle">
        <el-input
          v-model="form.limitTrickle"
          style="width: 370px; margin-right: 32px"
          placeholder="请输入"
          @input="form.limitTrickle = form.limitTrickle.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">%</span>
        </el-input>
      </el-form-item>
      <el-form-item label="单次刷卡金额" prop="chargeSingleAmount">
        <el-input
          v-model="form.chargeSingleAmount"
          style="width: 370px"
          maxlength="3"
          placeholder="请输入"
          @input="form.chargeSingleAmount = form.chargeSingleAmount.replace(/^\.+|[^\d]+/g, '')"
        >
          <span slot="suffix" class="cy_suffix">角</span>
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ["setType", "deviceId"],
  data() {
    return {
      form: {
        limitAmpere: "",
        limitPower: "",
        statusCycleTime: "",
        heartCycleTime: "",
        fullChargeDelay: "",
        nullChargeDelay: "",
        fullChargePower: "",
        nullChargePower: "",
        limitTemp: "",
        limitTime: "",
        limitTrickle: "",
        chargeSingleAmount: "",
      },
      rules: {
        limitAmpere: [{ required: true, message: "请输入过流限值", trigger: "blur" }],
        limitPower: [{ required: true, message: "请输入功率限值", trigger: "blur" }],
        statusCycleTime: [{ required: true, message: "请输入状态上报周期", trigger: "blur" }],
        heartCycleTime: [{ required: true, message: "请输入心跳周期", trigger: "blur" }],
        fullChargeDelay: [{ required: true, message: "请输入充满续充时长", trigger: "blur" }],
        nullChargeDelay: [{ required: true, message: "请输入空载延时时长", trigger: "blur" }],
        fullChargePower: [{ required: true, message: "请输入充满功率阈值", trigger: "blur" }],
        nullChargePower: [{ required: true, message: "请输入空载功率阈值", trigger: "blur" }],
        limitTemp: [{ required: true, message: "请输入高温阈值", trigger: "blur" }],
        limitTime: [{ required: true, message: "请输入最大充电时长", trigger: "blur" }],
        limitTrickle: [{ required: true, message: "请输入涓流阈值", trigger: "blur" }],
        chargeSingleAmount: [{ required: true, message: "请输入单次刷卡金额", trigger: "blur" }],
      },
    };
  },
  watch: {
    form(val) {
      this.$emit("input", val);
    },
    setType: {
      immediate: true,
      handler(val) {
        if (val == "set") {
          // 列表初始设置(获取默认参数)
          this.getDefaultParams();
        } else if (val == "reset") {
          // 重试
          this.form = JSON.parse(window.localStorage.getItem("devparams12"));
        } else if (val == "single") {
          // 详情单独设置(获取之前的设置参数)
          this.getParams();
        }
      },
    },
  },
  mounted() {},
  methods: {
    getDefaultParams() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getYd12DeviceDefaultParam,
          method: "get",
          params: {},
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.form = res.result.data;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    getParams() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getYd12DeviceParam,
          method: "get",
          params: {
            deviceId: this.deviceId,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.form = res.result.data;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    check() {
      return new Promise((resolve, reject) => {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            resolve(true);
          } else {
            reject("十二路");
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
  align-items: flex-start !important;
  line-height: 22px;
}
</style>
